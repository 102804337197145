import React from "react";
import styled from "styled-components";
import BannerBg from "assets/images/SectionBanner_bg.png";
import theme from "theme";
import classNames from "classnames";
import { Link } from "gatsby";

const SectionBanner = styled.div`
  width: 100%;
  padding: 150px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url(${BannerBg});

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 30px;
  color: #fff;

  ${theme.screens.xlScreen} {
    font-size: 28px;
  }

  ${theme.screens.mdScreen} {
    font-size: 24px;
  }
`;

const Desc = styled.div`
  font-size: 24px;
  text-align: center;
  max-width: 1000px;
  margin: 0 0 60px 0;
  color: #fff;

  ${theme.screens.mdScreen} {
    font-size: 20px;
    margin: 0 0 30px 0;
  }

  ${theme.screens.lgScreen} {
    font-size: 20px;
    margin: 0 0 30px 0;
  }
`;

// const Button = styled.a`
//   font-size: 18px;
//   padding: 17px 58px;
//   border: 1px solid #fff;
//   color: #fff !important;
//   background: none;
//   border-radius: 10px;
//   transition: all 0.2s;

//   &:hover {
//     background: white;
//     color: #ca3b6c !important;
//   }
// `;

const ContainerBanner = styled.div`
  padding-top: 80px;
  padding-bottom: 100px;
  position: relative;
  width: 100%;

  ${theme.screens.lgScreen} {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &::after {
    content: "";
    height: 225px;
    width: 1px;
    background-color: #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
`;

const Benefit = styled.div`
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 20px;
  color: #fff;

  @media (max-width: 1440px) {
    font-size: 20px;
  }

  ${theme.screens.lgScreen} {
    padding-bottom: unset;
    text-align: center;
  }
`;

const Banner = ({
  title,
  description,
  button,
  className,
  benefitPos,
  benefitNeg,
  link,
}) => {
  return (
    <SectionBanner className={className}>
      <div className="container">
        {title && <Title>{title}</Title>}
        {description && (
          <Desc dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {benefitPos && benefitNeg && (
          <ContainerBanner>
            <div className="row">
              <div className="col-lg-3 offset-xl-2 offset-lg-1">
                {benefitPos && (
                  <Benefit dangerouslySetInnerHTML={{ __html: benefitPos }} />
                )}
              </div>
              <div className="col-xl-4 col-lg-5 offset-xl-2 offset-lg-3">
                {benefitNeg && (
                  <Benefit dangerouslySetInnerHTML={{ __html: benefitNeg }} />
                )}
              </div>
            </div>
          </ContainerBanner>
        )}

        {button && (
          <Link to={link} className={classNames(className, `btn btn--outline`)}>
            {button}
          </Link>
        )}
      </div>
    </SectionBanner>
  );
};

export default Banner;
