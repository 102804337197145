import React from "react";
import styled from "styled-components";
import BannerBg from "assets/images/bg-pattern-light.png";
import theme from "theme";

const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 40px;

  @media screen and (max-width: 1600px) {
    max-width: 800px;
  }

  ${theme.screens.xlScreen} {
    font-size: 22px;
    max-width: 800px;
  }

  ${theme.screens.lgScreen} {
    font-size: 22px;
    max-width: 500px;
  }

  ${theme.screens.mdScreen} {
    font-size: 18px;
    max-width: 400px;
  }

  ${theme.screens.smScreen} {
    font-size: 20px;
  }
`;

const Desc = styled.div`
  font-size: 24px;
  text-align: center;
  max-width: 850px;
  margin: 0 auto;

  ${theme.screens.mdScreen} {
    font-size: 20px;
  }

  ${theme.screens.smScreen} {
    font-size: 16px;
  }
`;

const MainBanner = styled.div`
  width: 100%;
  padding: ${({ padding }) => (padding === "greater" ? "150px 0" : "110px 0")};
  background-repeat: no-repeat;
  background-size: 96%;
  background-position: center center;
  background-image: url(${BannerBg});

  @media (max-width: 1365px) {
    background-size: 141%;
    padding: 130px 60px;
  }

  ${theme.screens.smScreen} {
    padding: 50px 0;
  }
  @media (max-width: 440px) {
    padding: 15px 0;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  ${({ size }) =>
    size === "small" &&
    `
    ${Title} {
      font-size: 30px
    }
    ${Desc} {
      font-size: 20px
    }
  `}
`;

const Image = styled.img`
  @media (max-width: 1199px) {
    max-height: 150px;
  }

  @media (max-width: 575px) {
    max-height: 100px;
  }
`;

const Banner = ({ title, description, size, padding, bgColor, image }) => {
  return (
    <MainBanner size={size} padding={padding} bgColor={bgColor}>
      <div className="container">
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        {description && (
          <Desc dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {image && <Image src={image} alt="" />}
      </div>
    </MainBanner>
  );
};

export default Banner;
