import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import theme from "theme";

const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;

  ${theme.screens.xlScreen} {
    font-size: 32px;
  }

  ${theme.screens.smScreen} {
    font-size: 28px;
  }
`;

const Subtitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin: ${({ margin }) =>
    margin === "bottom"
      ? `0 0 110px 0`
      : margin === "top"
      ? `110px 0 0 0`
      : "55px 0"};

  ${theme.screens.lgScreen} {
    margin: ${({ margin }) =>
      margin === "bottom"
        ? `0 0 70px 0`
        : margin === "top"
        ? `70px 0 0 0`
        : "30px 0"};
  }

  ${theme.screens.smScreen} {
    margin: ${({ margin }) =>
      margin === "bottom"
        ? `0 0 30px 0`
        : margin === "top"
        ? `30px 0 0 0`
        : "15px 0"};
  }

  ${({ color }) =>
    color &&
    `${Title},
    ${Subtitle} {
      color: ${theme.colors[color]};
    }
  `}

  ${({ variant }) =>
    variant === "small"
      ? `
  ${Title} {
    font-size: 32px;
    ${theme.screens.smScreen} {
      font-size: 26px;
    }
  }
  `
      : variant === "verySmall"
      ? `
  ${Title} {
    font-size: 30px;
    ${theme.screens.smScreen} {
      font-size: 25px
    }
  }  `
      : ""}
`;

const SectionHeading = ({
  title,
  subtitle,
  variant,
  margin,
  color,
  className,
}) => {
  return (
    <SectionHeader
      variant={variant}
      color={color}
      margin={margin}
      className={className}
    >
      {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
      {subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </SectionHeader>
  );
};

SectionHeader.propTypes = {
  variant: PropTypes.oneOf([`normal`, `small`, `verySmall`]),
  color: PropTypes.oneOf([`white`, `blue`]),
  margin: PropTypes.oneOf([`bottom`, `top`, `both`]),
};

SectionHeader.defaultProps = {
  variant: "normal",
  color: "blue",
  margin: "bottom",
};

export default SectionHeading;
