import React, { useState } from "react";
import styled from "styled-components";
import theme from "theme";
import PropTypes from "prop-types";

const AccordionMain = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: ${theme.shadows.shadow};
  border-radius: 5px;
  padding: 40px;
  display: flex;

  ${theme.screens.mdScreen} {
    padding: 40px 15px;
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;

  ${theme.screens.mdScreen} {
    font-size: 18px;
  }
`;

const Description = styled.p`
  margin-top: 23px;
  display: none;
`;

const Item = styled.div`
  border-bottom: 1px solid ${theme.colors.gray};
  padding-bottom: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    ${Title} {
        color: ${theme.colors.pink};
    }

    ${Description} {
        display: block;
    }

    ${SingleImage} {
      display: block;
    }
  `}

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SingleImage = styled.img`
  display: block;
  margin: 0 auto;

  ${theme.screens.xlScreen} {
    margin-top: 30px;
  }
`;

const Accordion = ({ items, visibleOnStart, className }) => {
  const firstItem = items[Object.keys(items)[0]];
  const [selectedItem, setSelectedItem] = useState(firstItem.key);
  return (
    <div className="row">
      <div className={items[selectedItem].image ? "col-xl-6" : "col-12"}>
        <AccordionMain className={className}>
          <div className="accordion-container">
            {Object.values(items).map((item, index) => {
              return (
                <Item
                  visibleOnStart={visibleOnStart}
                  key={index}
                  active={
                    visibleOnStart ? visibleOnStart : selectedItem === item.key
                  }
                  onClick={(e) => setSelectedItem(item.key)}
                >
                  <Title>{item.title}</Title>
                  <Description>{item.desc}</Description>
                </Item>
              );
            })}
          </div>
        </AccordionMain>
      </div>
      <div className="col-6">
        <div className="image-container">
          {Object.values(items).map((item, index) => {
            return (
              item.key === selectedItem && (
                <SingleImage
                  key={index}
                  className="img-fluid"
                  src={item.image}
                />
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  visibleOnStart: PropTypes.oneOf([true, false]),
};

Accordion.defaultProps = {
  visibleOnStart: false,
};

export default Accordion;
