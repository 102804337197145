import React from "react";
import styled from "styled-components";
import theme from "theme";

const Image = styled.img`
  max-width: 100%;
  height: auto;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s;
`;

const Text = styled.div`
  opacity: 1;
  transition: all 0.25s;
`;

const SmallText = styled.div`
  font-size: 12px;
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s;
  width: 80%;

  ${theme.screens.smScreen} {
    width: 85%;
  }
`;

const Tile = styled.div`
  display: inline-block;
  font-size: 18px;
  text-align: ${({ variant }) => (variant === "big" ? "left" : "center")};
  background-color: #fff;
  transition: all 0.25s;
  border: ${({ border }) =>
    border === "bordered" ? "1px solid #CCCCCC" : "1px solid transparent"};
  border-radius: 5px;
  height: ${({ variant }) => (variant === "big" ? "unset" : "175px")};
  padding: ${({ variant }) =>
    variant === "big" ? "60px 90px 60px 35px" : "10px 15px"};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
  background-clip: padding-box;
  box-sizing: border-box;
  z-index: 1;

  ${({ withHover }) =>
    withHover &&
    `
    ${theme.mixins.onEvent} {
      ${Image}, ${SmallText} {
      opacity: 1;
    }

    ${Text} {
      opacity: 0;
    }
    }
  `}

  ${theme.screens.smScreen} {
    padding-left: 10px;
    padding-right: 0px;
  }
`;

const TileContainer = styled.div`
  position: relative;
  box-shadow: none;

  ${theme.mixins.onEvent} {
    box-shadow: ${theme.shadows.shadow};

    &:before {
      opacity: 1;
    }

    ${Tile} {
      border: 1px solid transparent !important;
    }
  }

  &:before {
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.25s;
    left: 0;
    z-index: 0;
    border-radius: 5px;
    background: linear-gradient(
      90deg,
      rgba(247, 160, 86, 1) 0%,
      rgba(228, 41, 120, 1) 50%,
      rgba(91, 5, 171, 1) 100%
    );
  }
`;

const WhiteTile = ({
  children,
  variant,
  className,
  border,
  img,
  hiddenText,
}) => {
  return (
    <TileContainer>
      <Tile
        withHover={img || hiddenText}
        variant={variant}
        border={border}
        className={className}
      >
        {img && <Image src={img} alt="" />}
        <Text dangerouslySetInnerHTML={{ __html: children }} />
        {hiddenText && <SmallText>{hiddenText}</SmallText>}
      </Tile>
    </TileContainer>
  );
};

export default WhiteTile;
