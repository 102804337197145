import React from "react";
import Layout from "components/Layout";
import TitleWithLine from "../components/TitleWithLine";
import SubpageHeader from "../components/SubpageHeader";
import SectionHeading from "components/SectionHeading";
import Seo from "components/Seo";
import Banner from "../components/Banner";
import Accordion from "../components/Accordion";
import WhiteTile from "../components/WhiteTile";
import SectionBanner from "../components/SectionBanner";
import "styles/pages/page-budgeting.scss";

const Budgeting = ({ pageContext }) => {
  const budgeting = pageContext.pageContent;

  const accordionItems = budgeting.sectionTobBudgets.map((item, index) => {
    return {
      key: index,
      desc: item.sectionTobBudgetTitle,
    };
  });
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/budgeting-bg.jpg").default}
      />
      <section className="section-budget">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-budget__inner">
                <div
                  className="section-budget__text"
                  dangerouslySetInnerHTML={{
                    __html: budgeting.sectionBudgetingIntroText,
                  }}
                ></div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src={budgeting.sectionBudgetingIntroImage?.sourceUrl}
                alt=""
                className="img-fluid section-budget__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-problem">
        <div className="container">
          <SectionHeading
            title={budgeting.sectionNoeTitle}
            subtitle={budgeting.sectionNoeText}
          />
          <div className="row">
            <div className="col-lg-6">
              <img
                src={budgeting.sectionNoeImage?.sourceUrl}
                alt=""
                className="img-fluid section-problem__img"
              />
            </div>
            <div className="col-lg-5">
              <div className="section-problem__inner">
                <TitleWithLine variantY="bottom">
                  {budgeting.sectionNoeCaption}
                </TitleWithLine>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-choose">
        <div className="container">
          <SectionHeading title={budgeting.sectionWtcpTitle} />
          <div className="row">
            {budgeting.sectionWtcpTiles.map((item, index) => {
              return (
                <div className="col-lg-3" key={index}>
                  <WhiteTile border="bordered">
                    {item.sectionWtcpTileTitle}
                  </WhiteTile>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Banner title={budgeting.budgetingDivider1Title} />
      <section className="section-types">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <img
                src={budgeting.sectionTobImage?.sourceUrl}
                alt=""
                className="img-fluid section-types__img"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-types__inner">
                <TitleWithLine variantY="bottom">
                  {budgeting.sectionTobTitle}
                </TitleWithLine>
                <p className="section-types__text">
                  {budgeting.sectionTobSubtitle}
                </p>
                <Accordion
                  className="accord"
                  visibleOnStart={true}
                  items={accordionItems}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <SectionBanner
        title={budgeting.benefitsContactDividerTitle}
        description={budgeting.benefitsContactDividerSubtitle}
        button="Contact"
        className=""
        link="/contact"
      />
    </Layout>
  );
};

export default Budgeting;
