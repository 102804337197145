import React from "react";
import styled from "styled-components";
import theme from "../../theme";

const PageHeader = styled.div`
  width: 100%;
  height: 360px;
  min-height: 360px;
  max-height: 360px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-top: 100px;
  position: relative;

  ${theme.screens.mdScreen} {
    height: 260px;
    min-height: unset;
    max-height: unset;
  }

  ${theme.screens.smScreen} {
    height: 200px;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;

    ${theme.screens.smScreen} {
      padding: 0 15px;
    }
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  color: #fff;

  ${theme.screens.mdScreen} {
    font-size: 32px;
  }

  ${theme.screens.smScreen} {
    font-size: 28px;
  }
`;

const Subtitle = styled.div`
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
`;

const SubpageHeading = styled.div`
  display: block;
`;

const SubpageHeader = ({ image, title, subtitle, className }) => {
  return (
    <PageHeader image={image}>
      <div className="container">
        <SubpageHeading className={className}>
          {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
          {subtitle && (
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
        </SubpageHeading>
      </div>
    </PageHeader>
  );
};

export default SubpageHeader;
