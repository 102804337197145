import React from "react";
import styled from "styled-components";
import theme from "theme";
import PropTypes from "prop-types";

const Title = styled.h2`
  font-size: 28px;
  font-weight: 500;
  position: relative;
  margin-bottom: 55px;
  color: ${({ color }) => color};

  &:before {
    content: "";
    position: absolute;
    background-color: ${theme.colors.pink};
    height: 3px;
    width: 73px;
    border-radius: 50px;
    ${({ variantX }) => (variantX === "left" ? "left: 0;" : "right: 0;")}
    ${({ variantY }) => (variantY === "top" ? "top: -19px;" : "bottom: -19px;")}

    ${theme.screens.lgScreen} {
      right: unset;
      left: 0;
    }
  }
`;

const TitleWithLine = ({ children, variantX, variantY, color }) => {
  return (
    <Title variantX={variantX} variantY={variantY} color={color}>
      {children}
    </Title>
  );
};

TitleWithLine.propTypes = {
  variantX: PropTypes.oneOf([`left`, `right`]),
  variantY: PropTypes.oneOf([`top`, `bottom`]),
};

TitleWithLine.defaultProps = {
  variantX: "left",
  variantY: "top",
};

export default TitleWithLine;
